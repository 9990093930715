* {
  padding: 0;
  margin: 0;
}

.admin_bjshow {
  float: left;
  width: calc(100% - 270px);
  height: 100%;
}

.admin_course_input {
  line-height: 50px;
  height: 50px;
  margin: 20px 0 0 30px;
  cursor: pointer;
}

.class_paging {
  margin: 20px 30px 30px 20px;
  float: right;
}

.admin_course_search {
  float: right;
  padding: 1px 15px 1px 15px;
  border-radius: 5px;
  color: #fff;
  background-color: #6b8bcc;
  height: 29px;
  line-height: 30px;
  margin: 7px 30px 0 0;
  font-size: 16px;
  width: 75px;
  height: 31px;
  text-align: center;
}

.notice_creatNotice {
  float: right;
}

.org_creatSchool {
  float: right;
}

.pb_tianjia {
  line-height: 50px;
  height: 50px;
  margin: 20px 25px 20px 0;
  cursor: pointer;
}

.pb_tianjia .text {
  float: right;
  color: #b8b8b8;
  font-size: 16px;
}

.pb_tianjia .icon {
  width: 30px;
  height: 30px;
  background: url(/img/add.png);
  background-repeat: no-repeat;
  background-size: 90%;
  float: right;
  margin-top: 13px;
}

.pb_head {
  font-size: 28px;
  border-bottom: 1px solid #dcdcdc;
  padding: 30px 0px 10px 0px;
  margin: 0 30px;
}

.pb_head p {
  color: #AAA;
  font-size: 16px;
  float: right;
  margin-top: 20px;
}

.admin_page {
  min-width: 1350px;
  height: 100%;
  background: #e6eaf0;
}

.admin_nav {
  font-size: 18px;
  float: left;
  width: 230px;
  background: #fff;
  margin: 20px 0px 0px 20px;
  border-radius: 5px;
  /* height: 900px; */
  height: 90%;
  padding: 20px 0 0 0;
}

.admin_nav img {
  position: relative;
  left: 0px;
  top: 12px;
}

.admin_nav div {
  cursor: pointer;
  text-align: center;
  height: 55px;
  line-height: 55px;
  position: relative;
  color: #777777;
}

.admin_nav .divhover {
  background: #3d67bc;
  color: #ffffff;
}

.admin_table {
  width: calc(100% - 40px);
  min-height: 90%;
  float: left;
  margin: 20px;
  background: #fff;
  font-size: 18px;
  border-radius: 5px;
}

.admin_table .admin_div {
  display: none;
}

.admin_table_block {
  display: block !important;
  position: relative;
  padding: .1px;
}

/*課程管理*/
.class_tbody li>div,
.class_head li>div {
  width: calc(100% / 8) !important;
}

.class_tbody li>div:last-child,
.class_head li>div:last-child {
  width: calc(100% / 8 + 22%) !important;
}

.class_tbody li .admin_width,
.class_head li .admin_width {
  width: 10% !important;
}

.class_tbody li .admin_Mwidth,
.class_head li .admin_Mwidth {
  width: 15% !important;
}

/*房間管理*/
.room_tbody li>div,
.room_head li>div {
  width: calc(100% / 7) !important;
}

.room_tbody li .admin_width,
.room_head li .admin_width {
  width: 15% !important;
}

.class_tbody li>div:last-child,
.class_head li>div:last-child {
  width: calc(100% / 8 + 18%) !important;
}

.room_tbody li .admin_Mwidth,
.room_head li .admin_Mwidth {
  width: 35% !important;
}

/*用戶管理*/
.user_tbody li>div,
.user_head li>div {
  width: calc((100% / 2.6) - 170px) !important;
}

.user_tbody li .admin_width,
.user_head li .admin_width {
  width: 15% !important;
}

.user_tbody li .admin_Mwidth,
.user_head li .admin_Mwidth {
  width: 300px !important;
}

/*通知管理*/
.notice_tbody li>div,
.notice_head li>div {
  width: calc(100% / 4.5) !important;
}

.notice_tbody li .admin_width,
.notice_head li .admin_width {
  width: 20% !important;
}

.notice_tbody li .admin_Mwidth,
.notice_head li .admin_Mwidth {
  width: 33% !important;
}

/*創建分組*/
.org_tbody li>div,
.org_head li>div {
  width: calc(100% / 3) !important;
}

.org_tbody li .admin_width,
.org_head li .admin_width {
  width: 200px !important;
}

.org_tbody li .admin_Mwidth,
.org_head li .admin_Mwidth {
  width: calc(100% - 200px) !important
}

/*權限*/
.jur_tbody li>div,
.jur_head li>div {
  width: calc(100% / 5) !important;
}

.jur_tbody li :nth-child(1),
.jur_head li :nth-child(1) {
  width: 20% !important;
}

.jur_tbody li :nth-child(2),
.jur_head li :nth-child(2) {
  width: calc(100% /10) !important;
}

.jur_tbody li :nth-child(3),
.jur_head li :nth-child(3) {
  width: calc(100% /5) !important;
}

.jur_tbody li :nth-child(4),
.jur_head li :nth-child(4) {
  width: calc(100% /9) !important;
}

.jur_tbody li :nth-child(5),
.jur_head li :nth-child(5) {
  min-width: 322px;
}

.jur_tbody li :nth-child(5) div {
  /* height: 40px;
  line-height: 40px;
  background: #386dcd;
  color: #fff;
  text-indent: 0;
  padding: 0 10px;
  box-sizing: unset !important;
  cursor: pointer;
  margin-left: 15px; */
  text-indent: 0;
  padding: 0 10px;
  box-sizing: unset !important;
  margin-left: 10px;
  color: #fff;
  cursor: pointer;
  text-align: center;
  background: #5190fd;
  display: inline-block;
  height: 40px;
  line-height: 40px;
  border-radius: 5px;
}


.jur_tbody div {
  width: calc(100% / 5) !important;
}

.jur_page {
  margin: 36px;
}

.jur_buttom {
  float: left;
  width: 100px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  background: #386dcd;
  color: #fff;
  margin: 10px 10px 0px 0px;
}

.jur_select {
  float: right;
  height: 50px;
  margin-right: 100px;
  margin-top: -48px;
}

.jur_selecrinput {
  margin-top: 5px;
  margin-right: 20px;
  height: 30px;
  float: left;
  border: 1px solid;
  width: 257px;
}

.jur_search {
  float: left;
  border: 1px solid;
  width: 100px;
  text-align: center;
  height: 40px;
  line-height: 40px;
  background: #6b8bcc;
  color: #fff;
  border-radius: 10px
}

/*表格样式*/
ul,
li {
  list-style: none;
  margin: 0;
  padding: 0;
}

.hs_dp_head {
  background-color: rgba(247, 247, 247, 1);
  height: 52px;
  line-height: 52px;
  text-align: center;
  border-bottom: 2px solid #eeeeee;
  color: black;
  font-weight: 600;
}

.hs_dp_head li {
  width: 100%;
}

.hs_dp_head li>div {
  float: left;
  width: calc(100% / 9);
  border-right: 2px solid #ffffff00;
  text-align: center;
}

.hs_dp_head li>div:last-child {
  float: left;
}

.hs_dp_tbody {
  background-color: #F7F7F7;
  overflow: auto;
}

.hs_dp_tbody li {
  float: left;
  width: 100%;
  height: 64px;
  line-height: 64px;
  border-bottom: 2px solid #eeeeee;
}

.room_page {
  margin: 30px 30px 30px 22px;
  float: right;
}

.hs_dp_tbody li:nth-child(odd) {
  background: #FFFFFF;
}

.hs_dp_tbody li:nth-child(even) {
  background: #F7F7F7;
}

.hs_dp_tbody li:nth-child(even)>div {
  border-right: 2px solid #eeeeee00;
}

.hs_dp_tbody li>div {
  float: left;
  text-align: center;
  width: calc(100% / 9);
  position: relative;
  font-size: 18px;
  text-overflow: ellipsis;
  color: #000000;
  white-space: nowrap;
  height: 64px;
  overflow: hidden;
  border-right: 2px solid #eeeeee;
  text-indent: 0.5em;
  padding: 0 5px;
}

.hs_dp_tbody li>div:last-child {
  float: left;
  font-size: 16px;
  border-right: none;
  text-overflow: ellipsis;
  overflow: inherit;
}

.user_head li>div,
.user_tbody li>div {
  width: calc(100% / 3 - 135px) !important;
}


.hs_dp_tbody li>div:last-child {
  border-right: none;

}

.hs_pub_tb {
  width: 95.5%;
  margin: 18px auto 0 auto;
  border: 2px solid #ebebeb;
  border-top: none;
}

.table {
  position: relative;
  border-collapse: collapse;
  border: solid #3b3b3b;
  border-bottom: none;
  white-space: nowrap;
  table-layout: fixed;
  display: table;
}

.hs_pub_updateBtn {
  color: #fff;
  cursor: pointer;
  text-align: center;
  width: 90px;
  margin: 0 0px;
  background: #5190fd;
  display: inline-block;
  height: 40px;
  line-height: 40px;
  border-radius: 5px;
}

.hs_pub_deleteBtn {
  color: #fff;
  cursor: pointer;
  text-align: center;
  width: 90px;
  margin: 0 5px;
  background: #5190fd;
  display: inline-block;
  height: 40px;
  line-height: 40px;
  border-radius: 5px;
}

.hs_pub_jurBtn {
  color: #fff;
  cursor: pointer;
  text-align: center;
  width: 90px;
  margin: 0 5px;
  background: #5190fd;
  display: inline-block;
  height: 40px;
  line-height: 40px;
  border-radius: 5px;
}

.hs_pub_jurBtn {
  color: #fff;
  cursor: pointer;
  text-align: center;
  width: 90px;
  margin: 0 5px;
  background: #5190fd;
  display: inline-block;
  height: 40px;
  line-height: 40px;
  border-radius: 5px;
}

/*首页管理*/
.banner_body {
  width: 90%;
  padding: 20px 0px 10px 30px;
}

.banner_control {
  overflow: hidden;
}

.banner_controlTop {
  width: 100%;
  margin-bottom: 25px;
  text-align: justify;
}

.banner_controlTop_l {
  height: 30px;
}

input {
  font-size: 18px;
}

#em {
  float: left;
}

h1 {
  float: left;
  margin: 0;
  font-size: 24px;
}

.banner_add {
  float: right;
  color: #7f7f7f;
  margin-top: 10px;
  font-size: 16px;
  cursor: pointer;
}

.banner_add>img {
  width: 20px;
  height: 21px;
  float: left;
}

ul,
li {
  list-style: none;
  margin: 0;
  padding: 0;
}

.hc_dp_head {
  height: 52px;
  line-height: 52px;
  text-align: center;
  border-bottom: 1px solid #eaeaea;
  color: #000;
  font-weight: 600;
}

.hc_dp_head li {
  width: 100%;
}

.hc_dp_head li :nth-child(1) {
  float: left;
  width: 24%;
  text-align: center;
}

.hc_dp_head li :nth-child(2) {
  float: left;
  width: 65%;
  text-align: center;
}

.hc_dp_head li :nth-child(3) {
  float: left;
  width: 10%;
  text-align: center;
}

.hc_dp_tbody {
  background-color: #F7F7F7;
}

.hc_dp_tbody li {
  float: left;
  width: 100%;
  height: 64px;
  line-height: 64px;
  border-bottom: 2px solid #eaeaea;
}

.hc_dp_tbody li:nth-child(odd) {
  background: #FFFFFF;
}

.hc_dp_tbody li:nth-child(even) {
  background: #F7F7F7;
}

.hc_dp_tbody>li> :nth-child(1) {
  width: 24%;
}

.hc_dp_tbody>li> :nth-child(1)>img {
  width: 83%;
  height: 97%;
}

.hc_dp_tbody>li> :nth-child(2) {
  width: 65%;
}

.hc_dp_tbody>li> :nth-child(2)>span {
  margin-left: 10px;
}

.hc_dp_tbody>li> :nth-child(3) {
  width: 10%;
  color: #ff5b5b;
  cursor: pointer;
  text-align: center;
}

.hc_dp_tbody>li> :nth-child(3)>span {
  border: 1px solid #5190fd;
  padding: 2px 15px;
  background: #5190fd;
  color: #fff;
}

.hc_dp_tbody li>div {
  float: left;
  position: relative;
  text-overflow: ellipsis;
  color: #000000;
  white-space: nowrap;
  height: 64px;
  overflow: hidden;
  border-right: 1px solid #eaeaea;
}

.hc_dp_tbody li>div:last-child {
  border-right: none;
  font-size: 16px;
}

.hc_pub_tb {
  width: 100%;
  border: 2px solid #ebebeb;
  border-top: none;
  margin-top: 12px;
}

.table {
  position: relative;
  border-collapse: collapse;
  border: 1px solid #eeeeee;
  border-top: 4px solid #e6eaf0;
  border-bottom: none;
  white-space: nowrap;
  table-layout: fixed;
  border-radius: 5px 5px 0 0;
  overflow: hidden;
  display: block;
}

.banner_addSpecial {
  float: left;
  width: 235px;
  height: 50px;
  margin: 25px 100% 30px 0;
  background-color: #4f7dbf;
  border-radius: 5px;
  cursor: pointer;
}

.banner_addSpecial>img {
  float: left;
  width: 35px;
  height: 35px;
  margin: 8px;
}

.banner_addSpecial>span {
  line-height: 50px;
  font-size: 16px;
  color: #ffffff;
}

.banner_special {
  margin-top: 30px;
  text-align: justify;
}

.banner_specialTop {
  height: 40px;
}

.banner_specialTopTitle {
  float: left;
}

.banner_specialTopTitle>img {
  float: left;
}

.banner_specialTopRight {
  float: right;
  color: #7f7f7f;
  font-size: 16px;
}

.banner_specialMeter {
  display: inline-block;
}

.banner_specialAddclass {
  float: left;
  margin: 10px 0px 10px 10px;
  cursor: pointer;
}

.banner_specialAddclass>img {
  float: left;
  width: 20px;
  height: 21px;
}

.banner_specialDelclass {
  float: left;
  margin: 10px 0px 10px 10px;
  cursor: pointer;
}

.banner_specialDelclass>img {
  float: left;
  width: 20px;
  height: 21px;
}

.hc_dd_head {
  height: 52px;
  line-height: 52px;
  text-align: center;
  border-bottom: 1px solid #eaeaea;
  font-weight: 600;
}

.hc_dd_head li {
  width: 100%;
}

.hc_dd_head li :nth-child(1) {
  float: left;
  width: 24%;
  text-align: center;
}

.hc_dd_head li :nth-child(2) {
  float: left;
  width: 45%;
  text-align: center;
}

.hc_dd_head li :nth-child(3) {
  float: left;
  width: 30%;
  text-align: center;
}

.hc_dd_head li :nth-child(4) {
  float: left;
  width: 10%;
  text-align: center;
}

.hc_dd_tbody>li> :nth-child(5)>span {
  border: 1px solid #5190fd;
  padding: 2px 15px;
  background: #5190fd;
  color: #fff;
}

.hc_dd_tbody>li> :nth-child(5) {
  width: 10%;
  color: #ff5b5b;
  cursor: pointer;
  text-align: center;
  text-align: center;
}

.hc_dd_tbody {
  background-color: #F7F7F7;
}

.hc_dd_tbody li {
  float: left;
  width: 100%;
  height: 64px;
  line-height: 64px;
  border-bottom: 2px solid #eaeaea;
}

.hc_dd_tbody li:nth-child(odd) {
  background: #FFFFFF;
}

.hc_dd_tbody li:nth-child(even) {
  background: #F7F7F7;
}

.hc_dd_tbody>li> :nth-child(1) {
  width: 24% !important;
  text-align: center;
  text-align: center;
}

.hc_dd_tbody>li> :nth-child(2) {
  width: 45%;
  text-align: center;
}

.hc_dd_tbody>li> :nth-child(2)>span {
  margin-left: 10px;
  text-align: center;
}

.hc_dd_tbody>li> :nth-child(3) {
  width: 10%;
  color: #358dcc;
  cursor: pointer;
  text-align: center;
  font-size: 16px;
}

.hc_dd_tbody>li> :nth-child(3)>span {
  border: 1px solid #5190fd;
  padding: 2px 15px;
  background: #5190fd;
  color: #fff;
}

.hc_dd_tbody>li> :nth-child(4)>span {
  border: 1px solid #5190fd;
  padding: 2px 15px;
  background: #5190fd;
  color: #fff;
}

.hc_dd_tbody>li> :nth-child(4) {
  width: 10%;
  color: #ff5b5b;
  cursor: pointer;
  text-align: center;
  text-align: center;
}

.hc_dd_tbody>li> :nth-child(5)>span {
  border: 1px solid #5190fd;
  padding: 2px 15px;
  background: #5190fd;
  color: #fff;
}

.hc_dd_tbody>li> :nth-child(5) {
  width: 10%;
  color: #ff5b5b;
  cursor: pointer;
  text-align: center;
  text-align: center;
}

.hc_dd_tbody li>div {
  float: left;
  position: relative;
  text-overflow: ellipsis;
  color: #000000;
  white-space: nowrap;
  height: 64px;
  overflow: hidden;
  border-right: 1px solid #eaeaea;
}

.hc_dd_tbody li>div:last-child {
  border-right: none;
  font-size: 16px;
}

.hc_ddbb_tbody {
  background-color: #F7F7F7;
}

.hc_ddbb_tbody li {
  float: left;
  width: 100%;
  height: 64px;
  line-height: 64px;
  border-bottom: 2px solid #787878;
}

.hc_ddbb_tbody li:nth-child(odd) {
  background: #FFFFFF;
}

.hc_ddbb_tbody li:nth-child(even) {
  background: #F7F7F7;
}

.hc_ddbb_tbody>li> :nth-child(1) {
  width: 24% !important;
  text-align: center;
  text-align: center;
}

.hc_ddbb_tbody>li> :nth-child(2) {
  width: 55%;
  text-align: center;
}

.hc_ddbb_tbody>li> :nth-child(2)>span {
  margin-left: 10px;
  text-align: center;
}

.hc_ddbb_tbody>li> :nth-child(3) {
  width: 10%;
  color: #2d89ca;
  cursor: pointer;
  text-align: center;
  text-align: center;
}

.hc_ddbb_tbody>li> :nth-child(4) {
  width: 10%;
  color: #ff5b5b;
  cursor: pointer;
  text-align: center;
  text-align: center;
}

.hc_ddbb_tbody li>div {
  float: left;
  position: relative;
  text-overflow: ellipsis;
  color: #000000;
  white-space: nowrap;
  height: 64px;
  overflow: hidden;
  border-right: 1px solid #787878;
}

.hc_ddbb_tbody li>div:last-child {
  border-right: none;
}

.banner_special_selectmore {
  width: 872px;
  height: 38px;
  float: left;
  font-size: 21px;
  line-height: 38px;
  background-color: #f6f6f6;
  border: 1px solid #787878;
  cursor: pointer;
  border-top: none;
  border-left: none;
}

.banner_special_selectmore>span {
  float: left;
  margin-left: 395px;
}

.banner_special_selectmore>img {
  width: 40px;
  height: 30px;
  margin-top: 5px;
  float: left;
}

.banner_special_selectmore>span {
  float: left;
  margin-left: 395px;
}

.banner_special_selectmore>img {
  width: 40px;
  height: 30px;
  margin-top: 5px;
  float: left;
}

.banner_course {
  margin-top: 44px;
  display: inline-block;
}

.banner_courseTop {
  width: 100%;
  height: 40px;
  float: left;
  margin-bottom: 25px;
}

.banner_courseTopTitle {
  float: left;
}

.banner_courseTopTitle>img {
  float: left;
  margin: 5px;
}

.banner_courseTopRight {
  float: right;
  width: 235px;
  color: #7f7f7f;
}

.banner_courseMeter {
  display: inline-block;
}

.banner_courseAddclass {
  float: left;
  margin: 12px;
}

.banner_courseAddclass>img {
  float: left;
  width: 20px;
  height: 21px;
}

.banner_courseDelclass {
  float: left;
  margin: 12px;
}

.banner_courseDelclass>img {
  float: left;
  width: 20px;
  height: 21px;
}

/*分頁box*/
.user_page {
  margin: 30px 22px;
  float: right;
}

.org_page {
  margin: 30px 22px;
  float: right;
}

.news_page {
  margin: 30px 22px;
  float: right;
}

/*彈窗按鈕*/
.form-btn {
  text-align: center;
  height: 85px;
  line-height: 85px;
  box-sizing: border-box;
}

.form-btn button {
  width: 110px;
  height: 42px;
  margin: 0 55px;
  border-radius: 7px;
  outline: none;
  cursor: pointer;
  font-size: 18px;
}

.form-confirmA {
  background: #fff;
  color: #6f6f6f;
  border: 1px solid #ccc;
  width: 80px !important;
  font-size: 15px !important;
  margin: 0 !important;
}

.form-cencalA {
  color: #fff;
  background: #409EFF;
  width: 80px !important;
  font-size: 15px !important;
  margin-left: 15px !important;
  margin-right: 0 !important;
  border: none;
}

.form-confirm {
  background: #d62b2b;
  border: none;
  color: #FFF;
}

.form-cencal {
  color: #000;
  background: #eaeaea;
  border: none;
}

/*彈窗內容*/
.form-main {
  box-sizing: border-box;
}

.form-content {
  line-height: 45px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.form_delete-main {
  justify-content: center;
  align-items: center;
}

.form_delete-main .form_delete-main_box {
  font-size: 19px;
  font-weight: 500;
}

.form-newscontent {
  line-height: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.form_input {
  margin-left: 10px;
}

.form_newsinputs .form_newsinput {
  margin-left: 10px;
  height: 150px;
}

.form_newsinput>textarea {
  resize: none;
  width: 450px;
  height: 150px;
}

.form_newsinputs>textarea {
  resize: none;
  width: 450px;
}

.form_input_div {
  font-size: 19px;
  font-weight: 500;
  margin: 25px 0;
}

.form_input_div span:first-child {
  width: 122px;
  display: inline-block;
  text-align: justify;
  text-align-last: justify;
  margin-right: 10px;
}

/*org_select*/
.org_select {
  width: 200px;
  border: 1px solid #dcdcdc;
  height: 40px;
  font-size: 18px;
  text-indent: 10px;
  display: inline-block;
}

.radio_label2 {
  margin: 0 5px;
}

/*课程权限弹窗样式*/
.jjj_body {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.jjj_head {
  width: 100%;
  background: #eaeaea;
}

.jjj_head div {
  display: inline-block;
  height: 50px;
  text-align: center;
  line-height: 50px;
}

.jjj_head div:last-child {
  float: right;
}

.jjj_head div:first-child {
  width: 30%;
}

.jjj_head div:last-child {
  width: 20%;
}

.jjj_box {
  width: 100%;
  overflow: auto;
}

.jjj_content {}

.jjj_content_head {
  width: 100%;
}

.jjj_content_head div {
  display: inline-block;
  height: 50px;
  text-align: center;
  line-height: 50px;
}

.jjj_content_head div:first-child {
  text-align: left;
  width: 80%;
  padding: 0 0 0 20px;
  box-sizing: border-box;
  cursor: pointer;
}

.jjj_content_head div:last-child {
  width: 20%;
  float: right;
}

.jjj_content_body {
  transition: all 0.5s;
  min-height: 0;
  max-height: 0;
  overflow: auto;
}

.jjj_content_list {
  width: 100%;
  border-bottom: 1px solid #a9a9a9;
  background: #f5f5f5;
}

.jjj_content_list:last-child {
  border-bottom: none;
}

.jjj_content_list:hover {
  background: #e4e4e4;
  color: #3d67bc;
  cursor: pointer;
}

.jjj_content_list div {
  display: inline-block;
  height: 35px;
  text-align: center;
  line-height: 35px;
}

.jjj_content_list div:first-child {
  text-align: left;
  width: 80%;
  padding: 0 0 0 40px;
  box-sizing: border-box;
}

.jjj_content_list div:last-child {
  width: 20%;
}

.jjj_content_sc {
  position: relative;
}

.select_sc .jjj_content_sc span:first-child {
  transform: rotate(90deg);
  margin-left: 5px;
}

.select_sc .jjj_content_body {
  max-height: 493px;
}

.jjj_content_sc span:last-child {
  margin-left: 15px;
}

.jjj_content_sc span:first-child {
  position: absolute;
  top: 20px;
  cursor: pointer;
  transition: all 1s;
}

.jjj_content_sc span:first-child:before,
.jjj_content_sc span:first-child:after {
  position: absolute;
  content: '';
  border-top: 6px transparent dashed;
  border-left: 6px #fff solid;
  border-bottom: 6px transparent dashed;
  border-right: 6px transparent dashed;
}

.jjj_content_sc span:first-child:before {
  border-left: 6px #757575 solid;
}

.jjj_content_sc span:first-child:after {
  left: -3px;
  /*覆盖并错开1px*/
  border-left: 6px #fff solid;
}

/*搜索*/
.jjj_search_container {
  height: 42px;
  width: 95%;
  margin: 8px 2.5%;
}

.jjj_search_parent {
  position: relative;
}

.jjj_search_search {
  width: 100%;
  height: 35px;
  border-radius: 3px;
  outline: none;
  border: 1px solid #ccc;
  padding-right: 50px;
  text-indent: 1em;
  font-size: 16px;
}

.jjj_search_btn {
  height: 30px;
  width: 30px;
  position: absolute;
  background: url(/img/topbar.png) no-repeat;
  top: 50%;
  right: 7px;
  border: none;
  outline: none;
  cursor: pointer;
  transform: translateY(-50%);
  background-size: 100% 100%;
}

.img_type_style {
  width: 310px;
  height: 120px;
  margin: 0;
}

.img_type_style2 {
  max-width: 100px;
  max-height: 90px;
  margin: 0 0 5px 0;
}

.flex_admin {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.admin_panel {
  border: 1px solid #ebeef5;
  background-color: #fff;
  color: #303133;
  border-radius: 4px;
  box-shadow: 0 2px 12px 0 rgb(0 0 0 / 10%);
  width: 95%;
  margin: 15px auto;
  padding: 15px;
  position: relative;
  height: 500px;
}

#addCourse {
  height: 665px !important;
}

.form-mainA {
  border: 1px solid #ebeef5;
  background-color: #fff;
  color: #303133;
  border-radius: 4px;
  box-shadow: 0 2px 12px 0 rgb(0 0 0 / 10%);
}

.searchA {
  height: 75px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border-bottom: 1px solid #eee;
  padding: 0 !important;
}

.inputA {
  padding: 10px !important;
  font-size: 14px !important;
  width: 75%;
}

.spanA {
  color: #408ceb;
  margin-left: 15px;
  cursor: pointer;
  display: block !important;
}

.objects {
  padding: 10px 0;
  height: 400px;
  overflow: hidden;
}

.object {
  width: 91%;
  margin: 0 auto 15px;
  font-size: 15px;
  display: flex;
  align-items: center;
  color: #2d2d2d;
  cursor: pointer;
}

.firstSpan,
.secondSpan,
.thirdSpan,
.fourSpan {
  margin-left: 10px;
  white-space: nowrap;
  overflow: hidden;
  text-align: center;
  text-overflow: ellipsis;
}

.admin_checkDiv {
  height: 35px;
  overflow: auto;
}

.admin_checkChild {
  height: 365px;
  overflow: auto;
}

.admin_checkChild::-webkit-scrollbar {
  /*滚动条整体样式*/
  width: 6px;
  /*高宽分别对应横竖滚动条的尺寸*/
  height: 6px;
}

/*定义滚动条轨道 内阴影+圆角*/
.admin_checkChild::-webkit-scrollbar-track {
  border-radius: 10px;
  background-color: #eee;
}

/*定义滑块 内阴影+圆角*/
.admin_checkChild::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, .3);
  background-color: rgba(0, 0, 0, 0.1);
}

.tc_admin_cont {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}