 html,
 body {
     width: 100%;
     height: 100%;
     margin: 0;
     padding: 0;
 }

 .info_btn:hover {
     background-color: #1f4082 !important;
 }

 textarea {
     resize: none;
 }

 .info_box {
     display: grid;
     grid-template-columns: 25% 75%;
     grid-template-areas: "steppers uploadArea";
     overflow: auto;
     height: calc(100vh - 135px);

 }

 .info_left {
     /* width: 30%; */
     border-right: 1px solid rgb(60, 94, 143);
     height: 100%;
     display: flex;
     flex-direction: column;
     align-items: center;
     min-height: 600px;
     /* overflow: auto; */
 }

 .info_tips {
     color: rgb(128, 128, 128);
     font-size: 12px;
     width: 270px;
     margin: 40px;
 }

 .info_steps {
     width: 270px;
     font-size: 0.875rem;
     display: flex;
     align-items: center;
 }

 .info_steps span:nth-child(1) {
     width: 30px;
     height: 30px;
     background: rgba(0, 0, 0, 0.38);
     display: block;
     color: #fff;
     border-radius: 40px;
     text-align: center;
     line-height: 30px;
 }

 .steps_active {
     background: #3D67BC !important;
 }

 .info_steps span:nth-child(2) {
     margin-left: 5px;
 }

 .info_solid {
     width: 270px;
     height: 30px;
     border-left: 1px solid #bdbdbd;
     margin: 10px 0px 10px 30px;
 }

 .info_right {
     /* width: 70%; */
     height: 100%;
     /* min-height: 600px; */
     /* overflow: auto; */
 }

 .info_btnBox {
     width: 100%;
     display: flex;
     justify-content: space-around;
     margin: 20px 0;
 }

 .info_btn {
     color: #fff;
     background-color: #3D67BC;
     padding: 8px 24px;
     font-size: 0.9375rem;
     box-shadow: 0px 1px 5px 0px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 3px 1px -2px rgb(0 0 0 / 12%);
     min-width: 64px;
     font-weight: 500;
     border-radius: 4px;
     box-sizing: border-box;
     border: none;
     cursor: pointer;
 }

 .cancelButton {
     color: #000 !important;
     background-color: #fff !important;
 }

 .cancelButton:hover {
     color: #fff !important;
     background-color: rgb(182, 182, 182) !important;
 }

 .Info_title {
     font-size: 2em;
 }

 .basic_info_box {
     width: 80%;
     min-height: 455px;
     margin: 48px auto;
     position: relative;
 }

 .bInfo_title {
     font-size: 16px;
     font-weight: bold;
     text-align: left;
     margin-top: 20px;
     margin-bottom: 10px;
 }

 .binfo_input {
     font: inherit;
     color: currentColor;
     width: 100%;
     margin: 0;
     padding: 15px 14px;
     display: block;
     min-width: 0;
     outline: none;
     box-sizing: content-box;
     background: none;
     -webkit-tap-highlight-color: transparent;
     border: 1px solid rgba(0, 0, 0, 0.23);
     border-radius: 4px;
     box-sizing: border-box;
 }

 .binfo_input:focus-visible {
     border: 1px solid rgba(61, 103, 188);
 }

 .icon_add {
     position: relative;
     width: 24px;
     padding-top: 20px;
     border-radius: 100%;
     border-width: 2px;
     border-style: solid;
     border-color: gray;
 }

 .icon_add i:nth-child(1) {
     position: absolute;
     left: 50%;
     top: 50%;
     height: 60%;
     transform: translate(-50%, -50%);
     border-width: 1px;
     border-style: solid;
     border-color: inherit;
 }

 .icon_add i:nth-child(2) {
     position: absolute;
     top: 50%;
     left: 50%;
     width: 60%;
     transform: translate(-50%, -50%);
     border-width: 1px;
     border-style: solid;
     border-color: inherit;
 }

 .chapter_btn_w {
     font-size: 0.9375rem;
     font-weight: bold;
     color: gray;
     margin-left: 20px;
 }

 .chapter_btnbox {
     width: 250px;
     border-radius: 5px;
     border: 2px dashed gray;
     display: flex;
     padding: 8px 50px;
     align-items: center;
     justify-content: center;
     margin: 30px auto 0;
     cursor: pointer;
 }

 .chapter_contentbox {
     display: flex;
     align-items: center;
     margin-top: 15px;
 }

 .chapter_contentbox div:nth-child(1) {
     width: 150px;
     margin: 0px;
     font-size: 2em;
     color: black;
     display: block;
     white-space: nowrap;
     overflow: hidden;
     text-overflow: ellipsis;
     font-weight: 600;
 }

 .chapter_contentbox div:nth-child(2) {
     width: 100%;
 }

 .chapter_contentbox div:nth-child(3) {
     background-image: url(../assets/img/remove.png);
     cursor: pointer;
     opacity: 0.5;
     width: 40px;
     height: 50px;
     background-repeat: no-repeat;
     background-position: 5px 10px;
 }

 .chapter_box {
     max-height: 300px;
     overflow: auto;
 }

 .chapter_count {
     font-size: 16px;
     color: rgb(204, 204, 204);
     margin: 20px 0 0 130px;
 }

 .chapter_info1 {
     margin: 30px 0;
 }

 .chapter_info_title {
     color: rgb(255, 255, 255);
     border-radius: 5px;
     padding: 10px;
     background: rgb(61, 103, 188);
     font-weight: bold;
     text-align: left;
     font-size: 18px;
     word-break: break-all;
     display: flex;
     align-items: center;
     justify-content: space-between;
 }

 .chapter_info_title img {
     width: 22px;
     margin-right: 10px;
     cursor: pointer;
 }


 .upload_contentBox {
     display: flex;
     flex-wrap: wrap;
 }

 .chapter_info_addBox {
     width: 40%;
     margin-right: 9%;
 }

 .chapter_uploadBox {
     text-align: left;
     background-color: rgb(242, 242, 242);
     width: 100%;
     height: 130px;
     padding: 0px 15px;
     border-radius: 8px;
     overflow: auto;
     font-size: 16px;
     box-sizing: border-box;
 }

 .chapter_uploadBox1 {
     text-align: left;
     background-color: rgb(242, 242, 242);
     width: 100%;
     height: 67px;
     padding: 0px 15px;
     border-radius: 8px;
     overflow: hidden;
     font-size: 16px;
     box-sizing: border-box;
     position: relative;
 }

 .chapter_beizhu {
     font-size: 12px;
     font-weight: bold;
     float: right;
     color: rgb(128, 128, 128);
     margin-top: 5px;
 }

 .chapter_add {
     width: 100%;
     height: 32px;
     margin-top: 15px;
     cursor: pointer;
 }

 .chapter_add_l {
     margin-left: 5px;
     /* line-height: 28px; */
     width: 30px;
     height: 30px;
     float: left;
     border: 1px solid #aaa;
     color: #aaa;
     border-radius: 50%;
     font-size: 25px;
     text-align: center;
 }

 .chapter_add_r {
     font-size: 18px;
     height: 40px;
     line-height: 30px;
     text-indent: 10px;
     color: #aaa;
 }

 .chapter_add_r span {
     font-size: 12px;
     color: rgb(204, 204, 204);
 }

 .chapter_add_input {
     display: none;
 }

 .info_line {
     align-self: center;
     height: 1px;
     border: none;
     flex-shrink: 0;
     background-color: rgba(0, 0, 0, 0.3);
     margin: 35px 0;
 }

 .chapter_info_btnbox {
     width: 100%;
     display: flex;
     justify-content: space-evenly;
     margin-top: 50px;
 }

 .success_img {
     width: 150px;
     height: 150px;
     place-self: center;
 }

 .success_num {
     text-decoration: underline;
     color: rgb(42, 151, 255);
     margin: 15px 0px;
     font-size: 28px;
 }

 .success_n {
     font-size: 24px;
 }

 .success_box {
     display: flex;
     flex-direction: column;
     justify-content: center;
     align-items: center;
     position: absolute;
     top: 50%;
     left: 50%;
     transform: translate(-50%, -50%);
 }

 .countInput {
     width: 50px;
     padding: 8px 8px;
     outline: none;
     border: 1px solid rgba(0, 0, 0, 0.23);
     border-radius: 4px;
     margin: 0 10px;
 }

 .btnBox {
     display: flex;
     width: 100%;
     justify-content: space-between;
 }

 .testBox {
     width: 800px;
 }

 .testBox span {
     font-size: 17px;
     word-break: break-all;
 }

 .testTitle {
     display: flex;
     margin: 20px 0;
     align-items: center;
 }

 .testTitle div {
     width: 50%;
     display: flex;
     align-items: center;
 }

 .testTitle input {
     padding: 9px 10px;
     outline: none;
     border: 1px solid rgba(0, 0, 0, 0.23);
     border-radius: 4px;
     width: 75%;
 }

 .testContent {
     background: #fff;
     padding: 10px 25px;
 }

 .testRadioBox {
     border-bottom: 2px solid rgb(226, 226, 226);
     margin-bottom: 25px;
 }

 .testRadioBox:last-child {
     border-bottom: none;
 }

 .testContentTitle {
     display: flex;
     align-items: center;
     justify-content: space-between;
     margin: 10px 0 10px;
 }

 .testContentTitle span {
     /* margin-right: 20px; */
     width: 60px;
 }

 .testContentTitle input {
     padding: 9px 10px;
     outline: none;
     border: 1px solid rgba(0, 0, 0, 0.23);
     border-radius: 4px;
     width: 100%;
 }

 .testContentTitle div {
     display: flex;
     align-items: center;
 }

 .testContentTitle div:nth-child(1) {
     /* width: 60%; */
 }

 .testContentTitle .addBtn {
     background-color: rgb(59, 142, 222);
 }

 .testContentTitle .addBtn:hover {
     background-color: rgb(45, 125, 201) !important;
 }

 .testContentTitle .deleteBtn {
     background-color: rgb(243, 98, 121);

 }

 .testContentTitle .deleteBtn:hover {
     background-color: rgb(206, 72, 94) !important;
 }

 .testRadio {
     display: flex;
     align-items: center;
 }

 .testRadio div {
     display: flex;
     align-items: center;
     flex-wrap: wrap;
     margin-top: 10px;
 }

 .testRadio div:nth-child(2) {
     /* margin-left: 20px; */
 }

 .testRadio span {
     width: 60px;
 }

 .testRadio input[type='text'] {
     padding: 9px 10px;
     outline: none;
     border: 1px solid rgba(0, 0, 0, 0.23);
     border-radius: 4px;
     width: 250px;
     margin-left: 10px;
 }

 .testRadio input[type='checkbox'] {
     margin: 0 5px;
     cursor: pointer;
 }

 .testRadio button {
     width: 35px;
     height: 35px;
     display: flex;
     justify-content: center;
     align-items: center;
     background-color: #fff;
     border-radius: 4px;
     cursor: pointer;
     margin-top: 5px;
 }

 .testRadio .addRadioBtn {
     border: 2px solid #3D67BC;
 }

 .testRadio .deleteRadioBtn {
     border: 2px solid #ff3656;
 }

 .testRadio button img {
     width: 70%;
 }

 .testRadio button:hover {
     background-color: rgb(236, 236, 236, .5) !important;
 }

 .testAnserBox {
     display: flex;
     align-items: center;
     margin: 10px 0;
 }

 .testAnserBox div {
     display: flex;
     align-items: center;
 }

 .testAnserBox span:nth-child(1) {
     min-width: 80px;
 }

 .testAnserBox input {
     padding: 9px 10px;
     outline: none;
     border: 1px solid rgba(0, 0, 0, 0.23);
     border-radius: 4px;
     width: 500px;
 }

 .add_info_box {
     margin: 20px 0;
 }

 .add_info_box button {
     margin: 0 10px 10px 0;
 }

 .add_chapters_box {
     text-align: left;
     background-color: rgb(242, 242, 242);
     width: 100%;
     height: 240px;
     padding: 0px 15px;
     border-radius: 8px;
     overflow: auto;
     font-size: 16px;
     box-sizing: border-box;
     position: relative;
     padding: 0 10px 5px;
 }

 .chapter_upload_l_i1 {
     background-image: url(../assets/img/video.png);
     width: 28px;
     height: 28px;
     margin: 10px auto 0 auto;
     background-size: 100% 100%;
 }

 .chapter_upload_l_i2 {
     background-image: url(../assets/img/test.png);
     width: 26px;
     height: 25px;
     margin: 10px auto 0 auto;
     background-size: 100% 100%;
 }

 .chapter_upload_l_i3 {
     background-image: url(../assets/img/code.png);
     width: 30px;
     height: 30px;
     margin: 10px auto 0 auto;
     background-size: 100% 100%;
 }

 .chapter_upload_l_i4 {
     background-image: url(../assets/img/pdf.png);
     width: 30px;
     height: 30px;
     margin: 10px auto 0 auto;
     background-size: 100% 100%;
 }

 .chapter_upload_l_i5 {
     background-image: url(../assets/img/manage/word.png);
     width: 24px;
     height: 24px;
     margin: 10px auto 0 auto;
     background-size: 100% 100%;
 }

 .chapter_upload_l_i6 {
     background-image: url(../assets/img/manage/ppt.png);
     width: 30px;
     height: 30px;
     margin: 10px auto 0 auto;
     background-size: 100% 100%;
 }

  .chapter_upload_l_i7 {
      background-image: url(../assets/img/html.png);
      width: 26px;
      height: 26px;
      margin: 10px auto 0 auto;
      background-size: 100% 100%;
  }


 .chapter_upload_ud {
     display: flex;
     flex-direction: column;
     margin-left: 5px;
 }

 .chapter_upload_up {
     background-image: url(../assets/img/up.png);
     width: 17px;
     height: 15px;
     background-size: 100% 100%;
     cursor: pointer;
 }

 .chapter_upload_down {
     background-image: url(../assets/img/down.png);
     width: 17px;
     height: 15px;
     margin: 2px auto 0 auto;
     background-size: 100% 100%;
     cursor: pointer;
 }

 .testBoxCheck {
     color: rgb(126, 126, 126);
     position: relative;
     /* margin: 0px 0 20px; */
 }

 .testBoxCheck span {
     cursor: pointer;
     height: 30px;
     text-align: center;
     font-size: 16px;
 }

 .testBoxCheck .active {
     color: #000;
     /* border-bottom: 3px solid #3D67BC; */
     /* padding: 5px 0; */
 }

 .testBoxCheck .line {
     border-bottom: 3px solid #3D67BC;
     position: absolute;
     left: 0px;
     transition: .3s all;
 }

 .testBoxCheck .lineX {
     left: 60px;
 }

 .testBoxCheck .lineZ {
     left: 120px;
 }

 .checkBBB {
     width: 100%;
     align-items: center;
     margin-bottom: 20px;
     justify-content: space-between;
 }

 .unit_title {
     font-size: 28px;
     margin-top: 10px;
     color: #a6a6a6;
     font-weight: 600;
 }

 .code_cu_textarea {
     padding: 9px 10px;
     outline: none;
     border: 1px solid rgba(0, 0, 0, 0.23);
     border-radius: 4px;
     width: 100%;
     font-size: 16px;
     height: 150px;
 }

 .cru_selectBox {
     display: flex;
     margin: 30px 0 10px;
     flex-wrap: nowrap;
     width: 100%;
     white-space: nowrap;
     overflow: auto;
     position: relative;
     height: 47px;
 }

 .cru_selectBox::-webkit-scrollbar {
     /*滚动条整体样式*/
     width: 6px;
     /*高宽分别对应横竖滚动条的尺寸*/
     height: 6px;
 }

 /*定义滚动条轨道 内阴影+圆角*/
 .cru_selectBox::-webkit-scrollbar-track {
     border-radius: 10px;
     background-color: #eee;
 }

 /*定义滑块 内阴影+圆角*/
 .cru_selectBox::-webkit-scrollbar-thumb {
     border-radius: 10px;
     -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, .3);
     background-color: rgba(0, 0, 0, 0.1);
 }

 .cru_line {
     position: absolute;
     bottom: 0px;
     transition: all .5s;
     left: 0px;
     width: 125px;
     margin-left: -25px;
 }

 .cru_select {
     font-size: 21px;
     margin-right: 37px;
     margin-left: 5px;
     cursor: pointer;
     color: #a6a6a6;
 }

 .cru_selected {
     color: #0b7fc2 !important;
 }

 .importButton {
     margin: 15px 0;
     display: flex;
     justify-content: flex-end;
 }

 .importContent {
     display: flex;
 }

 .importContent textarea {
     height: 350px;
     line-height: 1.5;
 }

 .importContent .textI {
     padding: 9px 10px;
     outline: none;
     border: 1px solid rgba(0, 0, 0, 0.23);
     border-radius: 4px;
     width: 100%;
     font-size: 16px;
     height: 350px;
     line-height: 1.5;
     background: #fff;
     overflow: auto;
 }

 .textI .testRadio span{
     font-size: 15px !important;
 }