li,
ul {
    list-style: none;
    margin: 0;
    padding: 0;
}

.pb_headT {
    font-size: 28px;
    border-bottom: 1px solid #dcdcdc;
    padding: 30px 0 10px;
    margin: 0 30px;
}

.admin_course_searchT {
    float: right;
    padding: 1px 15px;
    border-radius: 5px;
    color: #fff;
    background-color: #6b8bcc;
    height: 29px;
    line-height: 30px;
    margin: 7px 30px 0 0;
    font-size: 16px;
    width: 75px;
    height: 31px;
    text-align: center;
}

.tableT {
    position: relative;
    border-collapse: collapse;
    border: 1px solid #eee;
    border-top: 4px solid #e6eaf0;
    border-bottom: none;
    white-space: nowrap;
    table-layout: fixed;
    border-radius: 5px 5px 0 0;
    overflow: hidden;
    display: block;
}

.hs_pub_tbT {
    width: 95.5%;
    margin: 18px auto 0;
    border: 2px solid #ebebeb;
    border-top: none;
}

.hs_dp_headT {
    background-color: #f7f7f7;
    height: 52px;
    line-height: 52px;
    text-align: center;
    border-bottom: 2px solid #eee;
    color: #000;
    font-weight: 600;
}

.hs_dp_headT li {
    width: 100%;
}

.hs_dp_headT li>div {
    border-right: 2px solid #ffffff00;
    float: left;
    text-align: center;
    font-size: 18px;
    padding: 0 5px;
}

.hs_dp_headT li>div:last-child {
    /*width: 200px !important;*/
    float: left;
    /* width: 30% !important; */
}

.hs_dp_tbodyT {
    height: 400px;
    background-color: #F7F7F7;
    overflow: auto;
    /* height: auto; */
}

.hs_dp_tbodyT li {
    float: left;
    width: 100%;
    height: 64px;
    line-height: 64px;
    border-bottom: 2px solid #eeeeee;
}

.hs_dp_tbodyT li:nth-child(odd) {
    background: #FFFFFF;
}

.hs_dp_tbodyT li:nth-child(even) {
    background: #F7F7F7;
}

.hs_dp_tbodyT li:nth-child(even)>div {
    border-right: 2px solid #eeeeee00;
}

.hs_dp_tbodyT li>div {

    float: left;
    text-align: center;
    width: calc(100% / 9);
    position: relative;
    font-size: 18px;
    text-overflow: ellipsis;
    color: #000000;
    white-space: nowrap;
    height: 64px;
    overflow: hidden;
    border-right: 2px solid #eeeeee;
    text-indent: 0.5em;
    padding: 0 5px;
}

.hs_dp_tbodyT li>div:last-child {
    float: left;
    font-size: 16px;
    border-right: none;
    text-overflow: ellipsis;
    overflow: inherit;
}

.user_headT li>div,
.user_tbodyT li>div {
    width: calc(100% / 5 - 14px) !important;
}


.hs_dp_tbodyT li>div:last-child {
    border-right: none;

}

.hs_pub_updateBtnT {
    color: #fff;
    cursor: pointer;
    margin: 0 0px;
    background: #5190fd;
    padding: 10px 20px;
    border-radius: 5px;
}

.hs_pub_deleteBtnT {
    margin: 0 5px;
    color: #fff;
    cursor: pointer;
    background: #5190fd;
    padding: 10px 20px;
    border-radius: 5px;
}

.flex {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.checkModal {
    width: 55%;
    background: rgb(238, 238, 238);
    border-radius: 10px;
    position: relative;
    border: none;
    outline: none;
    height: 90%;
}

@media screen and (max-width:1400px) {
    .checkModal {
        width: 80%;
    }
}

.checkModal .title {
    /* position: relative; */
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    background-color: #3D67BC;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    padding: 0 24px;
}

.checkModal .title h2 {
    color: #fff;
    text-align: center;
    padding: 15px 0;
    font-size: 22px;
    font-weight: 500;
}

.checkModal .title span {
    /* position: absolute; */
    font-size: 25px;
    color: #fff;
    /* right: 20px; */
    /* top: 10px; */
    cursor: pointer;
}

.checkModal .submit {
    display: flex;
    justify-content: flex-end;
    margin: 20px 30px;
}

.checkModal .submit button {
    padding: 7px 28px;
    border: none;
    outline: none;
    border-radius: 5px;
    font-size: 20px;
    cursor: pointer;
    color: #000;
    border: 1px solid;
}

.checkTitle {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 40px;
    margin: 20px 0;
}

.checkTitle h2 {
    font-size: 22px;
    font-weight: 500;
}

.checkContent {
    background-color: #fff;
    width: calc(100% - 80px);
    margin: 0 auto;
    border-radius: 3px;
    padding-bottom: 30px;
    max-height: calc(100% - 55px);
    overflow: auto;
}

.checkBody {
    width: 95%;
    margin: 0 auto;
    padding: 30px 0;
    border-bottom: 3px solid rgb(219, 219, 219);
}

.checkBodyTitle {
    font-weight: 600;
    font-size: 18px;
}

.checkBodyC {
    font-size: 17px;
    color: rgb(163, 163, 163);
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    margin: 10px 0;
}

.checkBodyC div {
    display: flex;
    align-items: center;
}

.checkBodyC input {
    margin-right: 5px;
}

.checkBodyC input[type="radio"] {
    display: none;
}

.checkBodyC input[type="radio"]+span {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 20px;
    height: 20px;
    vertical-align: middle;
    border-radius: 50%;
    border: 1px solid #999;
    background-color: #fff;
    margin-right: 5px;
}

.checkBodyC input[type="radio"]:checked+span {
    border: 1px solid #39f;
    background-color: #fff;
}

.checkBodyC input[type="radio"]:checked+span span {
    width: 14px;
    height: 14px;
    background: #39f;
    border-radius: 30px;
    display: inline-block;
}

.checkBodyC input[type="radio"]:checked+.cc {
    border: 1px solid rgb(238, 74, 41);
    background-color: #fff;
}

.checkBodyC input[type="radio"]:checked+.cc span {
    width: 14px;
    height: 14px;
    background: rgb(238, 74, 41);
    border-radius: 30px;
    display: inline-block;
}