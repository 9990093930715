* {
  box-sizing: inherit;
}

html {
  box-sizing: border-box;
}

input {
  outline: 0px;
}

.App {
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, sans-serif;
}


.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}


.app_checkModal {
  width: 580px;
  background: #fff;
  border-radius: 5px;
  position: relative;
  border: none;
  outline: none;
  height: 230px;
}


.app_checkModal .title {
  /* position: relative; */
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  background-color: #3D67BC;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  padding: 0 24px;
}

.app_checkModal .title h2 {
  color: #fff;
  text-align: center;
  padding: 12px 0;
  font-size: 22px;
  font-weight: 500;
}

.app_checkModal .title span {
  /* position: absolute; */
  font-size: 25px;
  color: #fff;
  /* right: 20px; */
  /* top: 10px; */
  cursor: pointer;
}

.app_checkModal .submit {
  display: flex;
  justify-content: flex-end;
  margin: 13px 30px;
}

.app_checkModal .submit button {
  padding: 7px 28px;
  border: none;
  outline: none;
  border-radius: 5px;
  font-size: 20px;
  cursor: pointer;
  color: #fff;
}

.app_setNameRemarks {
  margin: 20px 35px;
  display: block;
}

.app_setNameContent {
  margin: 0px 50px;
  display: block;
}

.app_setNameContent span {}

.app_setNameContent span::before {
  content: '*';
  color: red;
}

.app_setNameContent input {
  height: 40px;
  margin-left: 5px;
  background: #fafafa;
  border-radius: 2px;
  text-indent: 1em;
}