.cClass_body {
    width: 90%;
    height: 100%;
    margin: 0 auto;
}

.cClass_box {
    display: flex;
    width: 300px;
    overflow: hidden;
    border-radius: 8px;
    flex-direction: column;
    margin: 0 40px 40px 0;
    box-shadow: 4px 4px 5px #e6e6e6;
    cursor: pointer;

}

.cClass_title {
    display: flex;
    align-items: center;
    font-size: 22px;
    font-weight: 600;
    margin: 30px 0;
}

.cClass_title img {
    margin-right: 10px;
}

.cClass_content {
    display: flex;
    flex-wrap: wrap;
}

.cClass_panel {
    background: url(../assets/img/manage/classifyB.png);
    background-size: 100% 100%;
    height: 181.8px;
    width: 388.8px;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin: 10px 35px 10px 0;
    cursor: pointer;
}

.cClass_panel span:first-child {
    font-size: 24px;
    width: 85%;
    text-align: center;
    text-overflow: -o-ellipsis-lastline;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    /* autoprefixer: off */
    -webkit-box-orient: vertical;
}

.cClass_panel span:last-child {
    font-size: 18px;
    margin-top: 10px;
    width: 65%;
    text-align: center;
    text-overflow: -o-ellipsis-lastline;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    /* autoprefixer: off */
    -webkit-box-orient: vertical;
}


.cClass_img {
    width: 100%;
    height: 170px;
}

.cClass_img img{
    width: inherit;
    height: inherit;
}

.cClass_zi {
    width: 100%;
    height: 50px;
    background: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 17px;
}

.cClass_zi span {
    width: 80%;
    text-align: center;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}