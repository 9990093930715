* {
    box-sizing: border-box !important;
}

.login {}

a {
    text-decoration: none;
}

.login .clearBoth:after {
    content: "";
    display: block;
    clear: both;
    zoom: 1;
}

.login .contain {
    width: 400px;
    margin: 0 auto;
    background: #fff;
    padding: 20px;
    box-shadow: 0 0 20px #eee;
}

.login .header span {
    font-weight: bold;
    color: #2a97ff;
    font-size: 16px;
}

.login .header span:nth-child(1) {
    float: left;
}

.login .header span:nth-child(2) {
    float: right;
}

.login .userLogin {
    margin-top: 25px;
    position: relative;
}

.Geetest {
    margin: 15px auto;
    /* width: 300px; */
    padding: 0 20px;
}

.login .userLogin img {
    /* width: 50%; */
    display: inline-block;
    width: 23px;
    padding-right: 5px;
    border-right: 1px solid #2a97ff;
    position: absolute;
    top: 13px;
    left: 5px;
}

.login .userLogin input {
    width: 100%;
    padding: 15px 0 15px 30px;
    outline: none;
    border: 1px solid #ccc;
    font-size: 14px;
}

.login .loginUser button {
    width: 100%;
    color: #fff;
    background: #2a97ff;
    padding: 10px;
    border-radius: 10px;
    border: none;
    font-size: 18px;
    text-align: center;
    outline: none;
    cursor: pointer;
}

.login .contain p:nth-child(1) {
    text-align: right;
    color: #2a97ff;
}

.login p {
    margin: 15px 0;
    font-size: 12px;
    text-align: center;
}

.login p span {
    color: #2a97ff;
    cursor: pointer;
}

.login .guge {
    font-size: 16px;
    color: #8590a6;
    height: 60px;
    line-height: 60px;
}

.login .guge div {
    float: right;
}

.login .guge div span {
    margin-left: 10px;
    cursor: pointer;
}

.login .guge div span img {
    vertical-align: middle;
    width: 18px;
    margin-right: 6px;
}

.login .guge button {
    width: 100%;
    background: #f26875;
    margin-top: 9px;
    font-size: 18px;
    border: none;
    outline: none;
    cursor: pointer;
    color: #fff;
    padding: 10px;
    border-radius: 10px;
    text-align: center;
}

/* 注册 */

.login .selectList {
    background: #fff;
    /* padding: 0 10px; */
    font-size: 14px;
    width: 100%;
    height: 125px;
    overflow-y: scroll;
    position: absolute;
    box-shadow: 2px 2px 5px #ccc;
    color: #555;
    z-index: 10;
}

.login .selectList li {
    padding: 6px;
}

.login .selectList li:hover {
    background: #e6f7ff;
}

.login .identity {
    margin: 15px 0;
    text-align: right;
    font-size: 12px;
    position: relative;
}

.login .identity div {
    width: 80px;
    float: right;
}

.login .identity input {
    width: 80px;
    outline: none;
    padding-left: 6px;
    border: 1px solid #ccc;
    font-size: 12px;
}

.login .identity div ul {
    border: 1px solid #ccc;
    border-top: none;
    position: absolute;
    width: 80px;
    background: #fff;
    margin-top: -1px;
}

.login .identity div ul li {
    padding-left: 6px;
}

.login .identity div ul li:hover {
    background: #2a97ff;
}

/* 忘记密码 */

/* .password{
    
} */

.password .email {
    width: 22%;
    min-width: 300px;
    margin: 10% auto;
    text-align: center;
    padding: 20px;
    box-shadow: 0 0 2px #ccc;
    height: 350px;
}

.password .email p {
    font-size: 18px;
    padding: 5px;
}

.password .email input {
    margin-top: 30px;
    width: 90%;
    padding: 10px;
}

.password .email button {
    margin-top: 50px;
    width: 90%;
    padding: 10px;
    border: none;
    outline: none;
    border-radius: 8px;
    background-color: #3d67bc;
    color: #fff;
    font-size: 20px;
}

.password .email .back {
    background: #c2c2c2;
}

/* 房间 */

.room .selectList {
    background: #fff;
    /* padding: 0 10px; */
    font-size: 14px;
    width: 100%;
    max-height: 93px;
    overflow-y: scroll;
    position: absolute;
    box-shadow: 2px 2px 5px #ccc;
    color: #555;
    z-index: 10;
}

.room .selectList li {
    padding: 6px;
}

.room .selectList li:hover {
    background: #e6f7ff;
}

/* 第三方登录页 */

.thirdLogin {
    background-color: #ccc;
    height: 100vh;
    position: relative;
    top: -67.5px;
}

.thirdLogin .contain {
    width: 23%;
    min-width: 400px;
    margin: 0 auto;
    background: #fff;
    padding: 20px;
    box-shadow: 0 0 20px #eee;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 10px;
}

.thirdLogin .contain p {
    text-align: center;
    font-size: 1.5rem;
    color: #8590a6;
}

.thirdLogin .user_input {
    margin: 25px 20px 0;
    position: relative;
}

.thirdLogin .user_input img {
    /* width: 50%; */
    display: inline-block;
    width: 23px;
    padding-right: 5px;
    border-right: 1px solid #2a97ff;
    position: absolute;
    top: 13px;
    left: 5px;
}

.thirdLogin .user_input input {
    width: 100%;
    padding: 15px 0 15px 30px;
    outline: none;
    border: 1px solid #ccc;
    font-size: 14px;
}

.thirdLogin .user_input .validation {
    width: calc(100% - 150px);
    padding: 10px 0 10px 10px;
}

.qqLogin {
    position: absolute;
    width: 50px;
    opacity: 0;
}

/*
.schoolNumsty::-webkit-input-placeholder{
    font-size: 7px;
}
*/

.login_resources {
    background-image: url('../assets/img/Vector 336.png'), url('../assets/img/Vector 337.png'), url('../assets/img/Vector 339.png');
    background-color: rgba(54, 129, 252);
    height: 100vh;
    background-position: left top, right top,right bottom;
    background-repeat: no-repeat, no-repeat,no-repeat;
}

.login_resources .clearBoth:after {
    content: "";
    display: block;
    clear: both;
    zoom: 1;
}

.login_resources .contain {
    width: 60%;
    min-width: 1000px;
    position: absolute;
    top: 50%; 
    left: 50%; 
    display: flex; 
    transform: translate(-50%, -50%);
    border-radius: 12px;
    box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.08);
    box-shadow: 0px 16px 24px 2px rgba(0, 0, 0, 0.04);
    box-shadow: 0px 6px 30px 5px rgba(0, 0, 0, 0.05);
}
.login_resources .contain .left_img{
    width: 50%;
}
.login_resources .contain .left_img img{
    width: 100%;
    height:100%;
    border-top-left-radius: 12px;
    border-bottom-left-radius: 12px;
}
.login_resources .header span {
    font-weight: bold;
    color: #2a97ff;
    font-size: 16px;
}

.login_resources .userLogin {
    position: relative;
}

.login_resources .userLogin img {
    /* width: 50%; */
    display: inline-block;
    width: 23px;
    padding-right: 5px;
    border-right: 1px solid #2a97ff;
    position: absolute;
    top: 13px;
    left: 5px;
}

.login_resources .userLogin input {
    width: 100%;
    padding: 15px 0 15px 30px;
    outline: none;
    border: 1px solid #ccc;
    font-size: 14px;
}

.login_resources .loginUser button {
    width: 100%;
    color: #fff;
    background: #2a97ff;
    padding: 10px;
    border-radius: 10px;
    border: none;
    font-size: 18px;
    text-align: center;
    outline: none;
    cursor: pointer;
}

.login_resources p {
    font-size: 12px;
    text-align: center;
}

.login_resources p span {
    color: #2a97ff;
    cursor: pointer;
}

.login_resources .guge {
    font-size: 16px;
    color: #8590a6;
    height: 60px;
    line-height: 60px;
}

.login_resources .guge div {
    float: right;
}

.login_resources .guge div span {
    margin-left: 10px;
    cursor: pointer;
}

.login_resources .guge div span img {
    vertical-align: middle;
    width: 18px;
    margin-right: 6px;
}

.login_resources .guge button {
    width: 100%;
    background: #f26875;
    margin-top: 9px;
    font-size: 18px;
    border: none;
    outline: none;
    cursor: pointer;
    color: #fff;
    padding: 10px;
    border-radius: 10px;
    text-align: center;
}

.login_resources .lognshadow{
    width: 50%;
    background-color: #fff;
    border-top-right-radius: 12px;
    border-bottom-right-radius: 12px;
}

.login_resources .p_title {
    margin: 20px 0 8px 0;
    padding: 0;
    text-align: left;
}

.login_resources .Login_h1 {
    position: relative;
}

.login_resources .Login_h1 p {
    font-size: 24px;
    font-weight: bold;
    text-align: left;
    margin: 0;
}

.login_resources .Login_h1 div {
    position: absolute;
    right: -100px;
    top: -126px;
    width: 50px;
    height: 50px;
    border-bottom-left-radius: 50px;
    background-color: rgba(54, 129, 252);
    color: #fff;
    cursor: pointer;
}

.login_resources .Login_h1 div span {
    font-size: 25px;
    position: relative;
    top: 3px;
    left: 18px;
}
.login_resources .select_role{
    margin-top: 24px;
}
.login_resources .select_role>div{
    display: inline-block;
    width: 128px;
    height: 128px;
    background-color: rgba(240, 242, 245);
    margin-right: 40px;
    border-radius: 6px;
    text-align: center;
    cursor: pointer;
    margin-bottom: 20px;
}
.login_resources .select_role>div:nth-child(2){
    margin-right: 0;
}
.login_resources .select_role>div:hover{
    background-color: rgba(224, 234, 251);
    box-shadow: 4px 4px 8px 0px rgba(54, 115, 232, 0.12);
    border: 1px solid rgb(54, 129, 252);
    color: #3681FC;
}
.login_resources .select_role>div:hover svg path{
    fill:#3681FC;
    /* transform: translateX(-48px); */
}
.login_resources .select_role div div{
    position: relative;
    top:50%;
    transform:translateY(-50%);
}
.login_resources .select_role div svg{
    width: 48px;
    height: 48px;
}
.login_resources .select_role div p{
    font-size: 16px;
}

.login_resources  .userLogin .course_input{
    margin-top: 10px;
    background-color: rgba(240, 242, 245, 1);
    padding: 10px;
}
.login_resources .course_code .next_button{
    width: 100px;
    border: 1px solid rgb(54, 129, 252);
    border-radius: 6px;
    background-color: #fff;
    color: rgb(54, 129, 252);
    font-size: 20px;
    padding: 4px 16px;
    margin-top: 16px;
    cursor: pointer;
}
.login_resources .course_code .student_list{
    margin: 15px 0;
}
.login_resources .course_code .student_list span{
    display: inline-block;
    width: 19%;
    background: rgba(240, 242, 245);
    text-align: center;
    border-radius: 3px;
    margin: 4px 0;
    padding: 6px 0;
    margin-right: 1%;
    cursor: pointer;
    border: 1px;
    font-size: 14px;
}
.login_resources .course_code .student_list span.select_active{
    color: rgba(54, 129, 252, 1);
    background-color: rgba(224, 234, 251, 1);
    border-color: rgba(54, 129, 252, 1);
}
.login_resources .course_code .student_list span:hover{
    color: rgba(54, 129, 252, 1);
    background-color: rgba(224, 234, 251, 1);
    border-color: rgba(54, 129, 252, 1);
}
@media screen and (max-width: 1700px){
    .login_resources .select_role>div{
        margin-right: 20px;
    }
}