@charset "utf-8";

.ss_container {
    background-color: #eee;
    width: 100%;
    height: 100%;
    padding: 1px 0;
    overflow: auto;
    border-radius: 10px;
}

.ss_container::-webkit-scrollbar {
    /*滚动条整体样式*/
    width: 6px;
    /*高宽分别对应横竖滚动条的尺寸*/
    height: 6px;
}

/*定义滚动条轨道 内阴影+圆角*/
.ss_container::-webkit-scrollbar-track {
    border-radius: 10px;
    background-color: #eee;
}

/*定义滑块 内阴影+圆角*/
.ss_container::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, .3);
    background-color: rgba(0, 0, 0, 0.1);
}

.ss_box {
    width: 98%;
    margin: 10px auto;
    display: flex;
    align-items: center;
    background-color: rgb(28, 173, 254);
    border-radius: 5px;
    height: 125px;
}

.ss_nameBox {
    border-right: 1px solid rgb(64, 158, 209);
    height: 90%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.ss_name {
    min-width: 185px;
    max-width: 150px;
    word-break: break-all;
    color: #fff;
    text-align: center;
    box-sizing: border-box;
    font-size: 18px;
    padding: 0 5px;
    text-overflow: -o-ellipsis-lastline;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    /* autoprefixer: off */
    -webkit-box-orient: vertical;
}


.ss_state_box {
    display: flex;
    align-items: center;
    overflow: auto;
    height: 100%;
}

.ss_line {
    border: 2px solid #33b6ff;
    position: absolute;
    width: 100%;
    left: 50%;
    right: -50%;
}

.ss_state_poi {
    width: 170px;
    min-width: 170px;
    position: relative;
    display: flex;
    justify-content: center
}

.ss_line_dian {
    position: absolute;
    left: calc(50% - 16px);
    right: -50%;
    top: -11px;
    width: 24px;
}

.ss_line_box {
    position: relative;
    width: 100%;
    display: flex;
    justify-content: center;
    top: -25px;
}

.ss_font {
    display: flex;
    justify-content: center;
    font-size: 14px;
    position: absolute;
    top: 16px;
    color: #fff;
    width: 80%;
    left: 12px;
    top: -8px;
    text-align: center;
}

.ss_font {
    text-overflow: -o-ellipsis-lastline;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    /* autoprefixer: off */
    -webkit-box-orient: vertical;
}

.ss_state_box::-webkit-scrollbar {
    /*滚动条整体样式*/
    width: 6px;
    /*高宽分别对应横竖滚动条的尺寸*/
    height: 6px;
}

/*定义滚动条轨道 内阴影+圆角*/
.ss_state_box::-webkit-scrollbar-track {
    border-radius: 10px;
    background-color: #eee;
}

/*定义滑块 内阴影+圆角*/
.ss_state_box::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, .3);
    background-color: rgba(0, 0, 0, 0.1);
}

.ss_score_controduce {}

.ss_score_detail {
    background-color: #fff;
    display: flex;
    flex-direction: column;
    padding: 10px 20px;
    width: 98%;
    margin: 10px auto;
}

.ss_score_teacher {
    background-color: #fff;
    display: flex;
    flex-direction: column;
    padding: 10px 20px;
    width: 98%;
    margin: 0px auto 10px;
}

.ss_info_box {
    margin: 0 5px;
    border-bottom: 1px solid #eee;
    padding-bottom: 10px;
    display: flex;
}

.ss_stu_info {
    background-color: rgb(240, 249, 255);
    height: 250px;
    width: 140px;
    align-items: center;
    justify-content: space-around;
    border-radius: 10px;
    /* border-right: 1px solid #eee; */
    margin-right: 20px;
    margin-bottom: 10px;
}

.ss_stu_info .sstitle {
    display: flex;
    align-items: center;
    justify-content: space-around;
    flex-direction: column;
}

.ss_stu_info .sstitle span {
    background-color: #009ef7;
    color: #fff;
    padding: 5px 10px;
    font-size: 16px;
    border-radius: 5px;
    max-width: 110px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    width: fit-content;
}


.ss_stu_info .ssScore {
    display: flex;
    align-items: center;
    justify-content: space-around;
    flex-direction: column;
}

.ss_stu_info .ssScore span {
    font-size: 75px;
    font-weight: 600;
    color: #009ef7;
    height: 85px;
}

.ss_stu_info .ssScore div {
    font-size: 14px;
    color: #7c7c7c;
}

.ss_radar {
    width: 350px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-left: 1px solid #eee;
}

.ss_state_panel {
    background: #fff;
    width: 275px;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 160px;
    margin: 0 15px 15px 0;
    box-shadow: -4px -4px 6px 0px #eee;
    border-radius: 8px;
    overflow: hidden;
    position: relative;
}

.ss_state_panel_title {
    color: rgb(62 102 187);
    font-size: 24px;
    width: 80%;
    text-align: center;
    margin: 10px 0;
    overflow: hidden;
    text-overflow: ellipsis;
}

.ss_state_panel_plan {
    width: 93%;
    font-size: 16px;
    color: #b4b4b4;
    margin: 2px 0 3px;
}

.ss_state_panel_planName {
    width: 93%;
    font-size: 16px;
    color: rgb(62 102 187);
    overflow: hidden;
    text-overflow: ellipsis;
    text-indent: -5px;
    white-space: nowrap;
}

.ss_state_panel_button {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 45px;
    background: #4da9ff;
    margin-top: 16px;
    color: #dce6f3;
    font-size: 15px;
    cursor: pointer;
}

.ss_score_homework_box {
    padding: 10px 0;
    display: flex;
    width: 100%;
    overflow: auto;
    flex-wrap: nowrap;
}

.ss_score_homework_box::-webkit-scrollbar {
    /*滚动条整体样式*/
    width: 6px;
    /*高宽分别对应横竖滚动条的尺寸*/
    height: 6px;
}

/*定义滚动条轨道 内阴影+圆角*/
.ss_score_homework_box::-webkit-scrollbar-track {
    border-radius: 10px;
    background-color: #eee;
}

/*定义滑块 内阴影+圆角*/
.ss_score_homework_box::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, .3);
    background-color: rgba(0, 0, 0, 0.1);
}

.ss_score_homework_panel {
    display: flex;
    flex-direction: column;
    min-width: 165px;
    height: 100px;
    background: rgb(249 249 249);
    border: 1px solid #e0e0e0;
    border-radius: 5px;
    align-items: center;
    justify-content: center;
    margin-right: 25px;
}

.ss_score_homework_panel span {
    height: 20px;
    font-size: 14px;
    width: 92%;
    margin: 0 auto;
    text-align: center;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.ss_score_homework_is {
    margin: 5px 0 !important;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #5e8ef1;
}

.sssh_is_box {
    background-color: rgb(247, 252, 255);
    border: 1px solid #009ef6 !important;
}

.sssh_is {
    color: #5e8ef1 !important;
}

.ss_score_homework_is div {
    display: flex;
    justify-content: center;
    align-items: center;
}

.ss_score_homework_is img {
    width: 20px;
    margin-right: 5px;
}

.ss_score_homework_none {
    color: #8c8c8c;
}