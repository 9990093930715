.sc_panel {
    width: 281px;
    height: 318px;
    border: 1px solid;
    border-radius: 5px;
    margin: 10px 0px 30px 25px;
    display: inline-block;
    overflow: hidden;
    border: 1px solid #d8d8d8;
    font-size: 16px;
}

.sc_panel_img {
    width: 100%;
    height: 165px;
    margin-bottom: 5px;
}

.sc_panel_img img {
    width: 100%;
    height: 100%;
}

.sc_panel_name {
    width: 310px;
    height: 32px;
    padding: 0px 15px;
    vertical-align: middle;
    color: #555;
    font-size: 18px;
    overflow: hidden;
    line-height: 45px;
    display: flex;
    align-items: center;
}


.sc_panel_btn {
    width: 100%;
    display: flex;
}

.sc_panel_btn div {
    width: 100%;
    height: 50px;
    border-top: 1px solid #e8e8e8;
    float: left;
    text-align: center;
    background-color: #f6f6f6;
    line-height: 50px;
    cursor: pointer;
}

.sc_panel_btn div:hover {
    background-color: #e2e2e2;
}