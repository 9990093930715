.sr_headT li>div,
.sr_tbodyT li>div {
    width: calc(100% / 7) !important;
}

.sr_search {
    display: flex;
    align-items: center;
}

.sr_select {
    margin-right: 10px;
}

.sr_select span {
    font-size: 15px;
    color: #444444;
}

.sr_select select {
    border: 1px solid #bbbbbb;
    border-radius: 5px;
    padding: 8px 10px;
    height: 35px;
    color: #acacac;
    width: 140px;
    font-size: 15px;
    outline: none;
    cursor: pointer;
}