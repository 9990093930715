.cm_container {
    width: 95%;
    height: 95%;
    overflow: auto;
    border-radius: 5px;
    background-color: #fff;
}

.cm_title {
    display: flex;
    align-items: center;
    margin: 40px 0 0 20px;
}

.cm_title img {}

.cm_title span {
    font-size: 35px;
    margin-left: 10px;
}

.cm_navs {
    display: flex;
    align-items: center;
    margin: 40px 0 20px 20px;
    position: relative;
}

.cm_nav {
    display: flex;
    align-items: center;
    margin-right: 23px;
    cursor: pointer;
}

.cm_nav img {
    height: 40px;
}

.cm_nav span {
    font-size: 18px;
    margin-left: 0px;
}

.cm_line {
    /* position: absolute;
    bottom: -20px;
    transition: all .5s;
    left: 0px; */
    position: absolute;
    bottom: -15px;
    transition: all .5s;
    left: 0px;
    width: 110px;
    margin-left: 5px;
}

.cm_line_chat {
    left: 175px;
}

.cm_line_juri {
    left: 345px;
}

.cm_line_test {
    left: 515px;
}

.cm_line_code {
    left: 680px;
}

.cm_line_sumup {
    left: 855px;
}

.cm_content {
    width: 98%;
    height: calc(100% - 225px);
    margin: 0 auto;
}

.cm_class_container {
    width: 100%;
    height: 100%;
    position: relative;
}

.cm_class_state {
    position: absolute;
    right: 0;
    top: -25px;
    margin-right: 5px;
    font-size: 15px;
    color: #a6a6a6;
}


.cm_class_container2 {
    width: 100%;
    height: 100%;
    position: relative;
    overflow: auto;
}

.cm_class_container2::-webkit-scrollbar {
    /*滚动条整体样式*/
    width: 6px;
    /*高宽分别对应横竖滚动条的尺寸*/
    height: 6px;
}

/*定义滚动条轨道 内阴影+圆角*/
.cm_class_container2::-webkit-scrollbar-track {
    border-radius: 10px;
    background-color: #eee;
}

/*定义滑块 内阴影+圆角*/
.cm_class_container2::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, .3);
    background-color: rgba(0, 0, 0, 0.1);
}