.e_imgB {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.e_imgB img {
    width: 130px;
}

.e_imgB div {
    width: 300px;
    text-align: center;
    margin: 10px 0 0 0;
}

.e_inputB {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-top: 10px;
}

.e_inputC {
    margin-top: 15px;
}

.e_inputS {
    font-size: 16px;
    font-weight: 600;
}

.e_must::before {
    content: '*';
    color: red;
}

.e_inputI input{
    width: 350px;
    height: 40px;
    padding-left: 8px;
    font-size: 14px;
    border: 1px solid #bbb;
    border-radius: 5px;
    margin-top: 8px;
}

.e_button{
  color: #fff !important;
  background: #5190fd;
  padding: 8px 20px;
  border-radius: 5px;
  font-size: 14px;  
  cursor: pointer;
}

.e_buttonB{
    margin: 50px 0 20px;
}