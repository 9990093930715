.sumModel {
    width: 600px;
    background: rgb(243, 243, 243);
    border-radius: 5px;
    position: relative;
    border: none;
    outline: none;
}

/* .sumModel div {
    position: relative;
} */

.sumModel .title h2 {
    background-color: #3D67BC;
    color: #fff;
    text-align: center;
    padding: 10px 0;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    font-size: 16px;
}

.sumModel .title span {
    position: absolute;
    /* font-size: 30px; */
    color: #fff;
    right: 20px;
    top: 10px;
    cursor: pointer;
}
.sumModel .title img{
    width: 20px !important;
}

.sumModel .submit {
    display: flex;
    justify-content: flex-end;
    margin: 10px 0 5px;
}

.sumModel .submit button {
    padding: 10px 36px;
    color: #fff;
    border: none;
    outline: none;
    border-radius: 5px;
    font-size: 20px;
    cursor: pointer;
    margin-left: 10px;
}

.score_box {
    /* height: 600px; */
}

.score_controduce {
    background-color: #fff;
    width: 98%;
    margin: 5px auto;
    border-radius: 3px;
    padding: 10px;
    max-height: 600px;
    overflow: auto;
}

.c_flex {
    display: flex;
    flex-direction: column;
}

.sumModel .uname {
    background-color: #009ef7;
    color: #fff;
    padding: 5px 10px;
    font-size: 14px;
    border-radius: 5px;
    width: fit-content;
}

.score_detail {
    margin-top: 10px;
}

.score_Star {
    display: flex;
    margin-top: 10px;
}

.score_Star img {
    width: 25px;
    height: 25px;
    margin-left: 8px;
    cursor: pointer;
}

.score_pBox {
    display: flex;
    align-items: flex-start;
}

.score_test {
    max-height: 200px;
    overflow: auto;
    color: #3c3c3c;
}

.score_test::-webkit-scrollbar {
    /*滚动条整体样式*/
    width: 6px;
    /*高宽分别对应横竖滚动条的尺寸*/
    height: 6px;
}

/*定义滚动条轨道 内阴影+圆角*/
.score_test::-webkit-scrollbar-track {
    border-radius: 10px;
    background-color: #eee;
}

/*定义滑块 内阴影+圆角*/
.score_test::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, .3);
    background-color: rgba(0, 0, 0, 0.1);
}

.score_test_box {
    margin-top: 10px;
    padding-left: 10px;
}

.score_test_title {
    border-left: 5px solid #009ef7;
    padding-left: 5px;
}

.score_test_xuan {
    margin: 10px 0px
}

.score_test_tian {}

.score_test_tian input {
    width: 50px;
    background: #f1f1f1;
    height: 35px;
    text-indent: 5px;
    margin-right: 5px;
    border-radius: 0;
}