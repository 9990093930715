@charset "utf-8";

* {

    padding: 0;
    margin: 0;
    /* text-align: initial; */
}

.lt_body {
    border: 6px solid #E0DEDF;
    border-radius: 8px;
    min-width: 650px;
    height: 90%;
    margin: 20px 0 0 30px;
    position: relative;
    max-width: 50%;
    /* max-height: 100%; */
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
}

.lt_title {
    background: #6C98D5;
    color: #fff;
    height: 35px;
    line-height: 35px;
    border-radius: 5px 5px 0 0;
    padding-left: 15px;
    font-size: 16px;
    width: 100%;
}

.lt_content {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    overflow: auto;
    height: 450px;
    padding: 0 0 50px 0;
}

.lt_content::-webkit-scrollbar {
    /*滚动条整体样式*/
    width: 6px;
    /*高宽分别对应横竖滚动条的尺寸*/
    height: 6px;
}

/*定义滚动条轨道 内阴影+圆角*/
.lt_content::-webkit-scrollbar-track {
    border-radius: 10px;
    background-color: #eee;
}

/*定义滑块 内阴影+圆角*/
.lt_content::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, .3);
    background-color: rgba(0, 0, 0, 0.1);
}

.lt_first {
    display: flex;
    margin: 20px 0 0 20px;
}

.left {
    width: 60px;
    height: 60px;
    border-radius: 50%;
    /* margin: auto 0; */
    overflow: hidden;
    border: 1px solid transparent;
}

.left>img {
    width: 100%;
    height: 100%;
}

.name {
    color: #878787;
    font-size: 14px;
    padding: 5px 0 10px 10px;
}

.pContent {
    background: #6DBD4A;
    border-radius: 10px;
    color: #fff;
    font-size: 14px;
    padding: 10px;
    max-width: 300px;
    /* letter-spacing: 0.3em; */
    margin-left: 5px;
    word-break: break-word;
}

.bottom {
    width: 98%;
    margin: 0 auto 10px;
    align-items: center;
    display: flex;
}

.sendInput {
    height: 45px;
    border: 1px solid #eaeaea;
    font-size: 14px;
    border-radius: 0;
    width: 100%;
    padding-left: 10px;
}

.sendMessage {
    height: 40px;
    margin-left: 5px;
    background: #6DBD49;
    border: none;
    width: 110px;
    color: #fff;
    font-size: 13px;
    line-height: 40px;
    text-align: center;
    cursor: pointer;
    border-radius: 5px;
}

.sc_opClose {
    margin: 20px 0 0 30px;
    position: relative;
    width: 130px;
    height: 75px;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    top: 55%;
}

.sc_open {
    border: 6px solid #E0DEDF;
    border-radius: 8px;
    width: 120px;
    color: #fff;
    font-size: 15px;
    text-align: center;
    line-height: 40px;
    background: #668BD6;
    cursor: pointer;
}

.sc_open_active{
    background: #ADC0E5 !important;
}

.sc_close {
    border: 6px solid #E0DEDF;
    border-radius: 8px;
    margin-top: 15px;
    width: 120px;
    color: #fff;
    font-size: 15px;
    text-align: center;
    line-height: 40px;
    background: #E3B3B3;
    cursor: pointer;
}

.sc_close_active{
    background: #D56262 !important;
}

.dis_play{
    display: flex;
}

.closeSay{
    text-align: center;
    margin-bottom: 10px;
    color: #969696;
}