/* 添加学生 */
.AddStudent {
    position: relative;
}

.AddStudent button {
    cursor: pointer;
}

.pb_head {
    font-size: 28px;
    border-bottom: 1px solid #3c66bd;
    padding: 30px 0px 10px 0px;
    margin: 0 30px;
    /* margin: 0px 500px 0px 30px; */
}

.pb_head p {
    float: right;
}

.addClassModal {
    width: 500px;
    background: #fff;
    border-radius: 5px;
    position: relative;
    border: none;
    outline: none;
}

/* @media screen and (max-width:1400px) {
    .addClassModal {
        width: 80%;
    }
} */

.addClassModal div {
    position: relative;
}

.addClassModal div h2 {
    background-color: #3D67BC;
    color: #fff;
    text-align: center;
    padding: 10px 0;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    font-size: 16px;
}

.addClassModal div span {
    position: absolute;
    /* font-size: 30px; */
    color: #fff;
    right: 20px;
    top: 10px;
    cursor: pointer;
}

.addClassModal .title img {
    width: 20px !important;
}

.addClassModal ul {
    width: 90%;
    margin: 40px auto;
}

.addClassModal ul li {
    padding: 15px;
}

.addClassModal ul li span {
    display: inline-block;
    width: 50px;
}

.addClassModal ul li .inputs {
    width: 50%;
}

.addClassModal ul li input {
    padding: 10px;
}

.addClassModal .submit {
    display: flex;
    justify-content: space-around;
    margin-bottom: 50px;
}

.addClassModal .submit button {
    padding: 10px 36px;
    color: #fff;
    border: none;
    outline: none;
    border-radius: 5px;
    font-size: 20px;
    cursor: pointer;
}

.addName{
    display: inline-block;
    width: 75px !important;
    margin-left: 50px;
}

.student .downloadSpan {
    float: right;
    font-size: 16px;
    color: #fff;
    padding: 8px 12px;
    background-color: #3c66bd;
    margin-right: 12px;
    border-radius: 5px;
    cursor: pointer;
}

.student input {
    position: absolute;
    right: 107px;
    padding: 5px 0px;
    color: #fff;
    background-color: #3c66bd;
    font-size: 16px;
    border: none;
    outline: none;
    border-radius: 5px;
    margin-right: 12px;
    width: 88px;
    opacity: 0;
    filter: alpha(opacity=0);
    cursor: pointer;
}

.student button {
    float: right;
    padding: 8px 12px;
    color: #fff;
    background-color: #3c66bd;
    font-size: 16px;
    border: none;
    outline: none;
    border-radius: 5px;
    margin-right: 12px;
}

.hp_My {
    color: #a3a3a3;
    font-size: 24px;
}

/* 查询 */
.search {
    padding: 18px 30px;
}

.search input {
    padding: 8px 10px;
    font-size: 14px;
}

.search button {
    border: none;
    outline: none;
    padding: 8px 12px;
    margin-left: 15px;
    border-radius: 5px;
    color: #fff;
    background-color: #2268BC;
}

.delete{
    color: #fff !important;
    background: #5190fd;
    padding: 8px 20px;
    border-radius: 5px;
    font-size: 14px;
}